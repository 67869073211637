import { Box, Typography } from 'ui';
import { CheckCircle } from 'ui/icons';

export const PlanDetail = ({ text, color }: { text: string; color: string }) => {
  return (
    <Box display="flex" mb={1}>
      <CheckCircle fontSize="medium" sx={{ color }} />
      <Typography variant="subtitle1" sx={{ ml: 2 }}>
        {text}
      </Typography>
    </Box>
  );
};
