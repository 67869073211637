import { useTranslation } from 'next-i18next';
import { Box, Typography } from 'ui';

import { LinkContainer } from '~/ui';

import { Card } from './Card';
import { CardTitle } from './CardTitle';
import { SubTitle } from './SubTitle';

export const TokiGPTCard = () => {
  const { t } = useTranslation();

  return (
    <Card background={{ backgroundImage: 'url(/images/home-gpt-card-bg.jpg)', backgroundSize: 'cover' }}>
      <Box flex={1} display="flex" flexDirection="column">
        <Box display="inline" mb={2}>
          <CardTitle color="white">{t('home:gptCard.title')}</CardTitle>
        </Box>

        <Box mb={2}>
          <SubTitle color="white">{t('home:gptCard.subtitle')}</SubTitle>
        </Box>
      </Box>

      <Box alignSelf="flex-end">
        <LinkContainer href="/toki-gpt">
          <Box
            py={1}
            px={2}
            borderRadius="14px"
            sx={{
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
              },
              backgroundColor: 'info.main',
              color: 'white',
            }}
          >
            <Typography flexShrink={0} display="inline" fontWeight="bold">
              {t('home:gptCard.button')}
            </Typography>
          </Box>
        </LinkContainer>
      </Box>
    </Card>
  );
};
