/* eslint-disable sort-keys-fix/sort-keys-fix */
import { useTranslation } from 'next-i18next';
import { BillingPlans } from 'types';
import { CardListContainer } from 'ui';

import { PlanCard } from '../detail';

type PlanDetails = {
  [planType: string]: {
    price: number;
    billingPlan: keyof typeof BillingPlans;
    isGreen: boolean;
  };
};

const planDetails: PlanDetails = {
  free: {
    billingPlan: 'plan1',
    isGreen: false,
    price: 0.257,
  },
  standard: {
    billingPlan: 'plan8',
    isGreen: false,
    price: 0.24,
  },
  exchange: {
    billingPlan: 'plan11',
    isGreen: false,
    price: 0.019,
  },
  greenStandard: {
    billingPlan: 'plan3',
    isGreen: true,
    price: 0.265,
  },
  greenExchange: {
    billingPlan: 'plan2',
    isGreen: true,
    price: 0.021,
  },
};

interface Props {
  baseUrl: string;
}

export const PlanCardList = ({ baseUrl }: Props) => {
  const { t } = useTranslation('plans');

  return (
    <CardListContainer
      cardWidth="md"
      elements={Object.entries(planDetails).map(([planType, { isGreen, price, billingPlan }]) => (
        <PlanCard
          title={t(`plans.${planType}.title`)}
          subtitle={isGreen ? t(`plans.${planType}.subtitle`) : undefined}
          details={t(`plans.${planType}.details`, { returnObjects: true })}
          price={
            planType.toLowerCase().includes('exchange')
              ? t('exchangePrice', { exchangePrice: price.toFixed(3) })
              : t('price', { price: price.toFixed(3) })
          }
          isGreen={isGreen}
          billingPlan={billingPlan}
          key={billingPlan}
          baseUrl={baseUrl}
        />
      ))}
    />
  );
};
