import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { BillingPlans } from 'types';
import { Box, Button, Card, CardContent, CardHeader, Typography } from 'ui';

import { PlanDetail } from './PlanDetail';

interface Props {
  title: string;
  subtitle?: string;
  details: string[];
  price: string;
  isGreen: boolean;
  billingPlan: keyof typeof BillingPlans;
  baseUrl: string;
}

export const PlanCard = ({ title, subtitle, details, price, isGreen, billingPlan, baseUrl }: Props) => {
  const { t } = useTranslation('plans');
  const { push } = useRouter();

  return (
    <Box display="flex" flexDirection="column">
      <Card sx={{ alignSelf: 'center', borderRadius: 4, height: 420, p: 2, width: '95%' }}>
        <CardHeader
          title={
            <Typography variant="h5" fontWeight="bold">
              {title}
            </Typography>
          }
          subheader={
            <Typography variant="h5" fontWeight="bold" color={isGreen ? 'primary.main' : 'secondary.main'}>
              {subtitle}
            </Typography>
          }
        />
        <CardContent sx={{ pt: 0 }}>
          {details.map((detail) => (
            <PlanDetail key={detail} text={detail} color={isGreen ? 'primary.main' : 'secondary.main'} />
          ))}
        </CardContent>
      </Card>
      <Box
        sx={{
          backgroundColor: isGreen ? 'primary.main' : 'secondary.main',
          borderRadius: 4,
          height: 120,
          mt: -2,
          p: 2,
        }}
      >
        <Typography variant="h5" color="common.white" mb={1} noWrap>
          {price}
        </Typography>
        <Button
          color={isGreen ? 'white' : 'secondaryWhite'}
          variant="secondary"
          size="md"
          onClick={() => push(`${baseUrl}?plan=${billingPlan}`)}
        >
          {t('choosePlan')}
        </Button>
      </Box>
    </Box>
  );
};
