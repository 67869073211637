import { Box } from 'ui';

interface CardProps {
  children: React.ReactNode;
  background: {
    backgroundColor?: string;
    backgroundImage?: string;
    backgroundSize?: string;
  };
}

export const Card = ({ children, background }: CardProps) => (
  <Box
    height="100%"
    borderRadius={4}
    justifyItems="center"
    alignItems="flex-start"
    flex={1}
    display="flex"
    flexDirection="column"
    p={4}
    sx={({ customShadows }) => ({
      ...background,
      borderWidth: 0,
      boxShadow: customShadows.main,
    })}
  >
    {children}
  </Box>
);
