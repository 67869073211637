import { Typography, useResolution } from 'ui';

interface CardTitleProps {
  children?: React.ReactNode;
  color?: string;
}

export const CardTitle = ({ children, color }: CardTitleProps) => {
  const { isDesktop } = useResolution();

  return (
    <Typography variant={isDesktop ? 'h3' : 'h4'} flexShrink={0} display="inline" fontWeight="bold" color={color}>
      {children}
    </Typography>
  );
};
