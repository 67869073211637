import { Typography } from 'ui';

interface SubTitleProps {
  children: React.ReactNode;
  color?: string;
}

export const SubTitle = ({ children, color }: SubTitleProps) => {
  return (
    <Typography variant="h5" fontSize="1.7rem" color={color}>
      {children}
    </Typography>
  );
};
