import { Trans, useTranslation } from 'next-i18next';
import { Box, Typography, useResolution } from 'ui';

export const Header = () => {
  const { isDesktop } = useResolution();
  const { t } = useTranslation();

  return (
    <Box>
      <Box mb={2}>
        <Trans
          i18nKey="home:title"
          components={[
            <Typography variant={isDesktop ? 'h3' : 'h4'} flexShrink={0} display="inline" />,
            <Typography
              data-testid="page-title"
              variant={isDesktop ? 'h3' : 'h4'}
              flexShrink={0}
              display="inline"
              color="primary.main"
            />,
            <Typography variant={isDesktop ? 'h3' : 'h4'} flexShrink={0} display="inline" />,
          ]}
        />
      </Box>

      <Typography variant="h6" marginBottom={2}>
        {t('home:subtitle')}
      </Typography>
    </Box>
  );
};
