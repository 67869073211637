import Image from 'next/image';

interface CardProps {
  src: string;
}

export const CardImage = ({ src }: CardProps) => (
  <Image
    src={src}
    width={110}
    height={110}
    alt=""
    style={{
      // eslint-disable-next-line no-inline-styles/no-inline-styles
      borderRadius: '50%',
      // eslint-disable-next-line no-inline-styles/no-inline-styles
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    }}
  />
);
