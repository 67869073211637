import Head from 'next/head';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { Home } from '~/home';
import { UnauthNavigationLayout } from '~/ui';

const HomePage = () => {
  const { t } = useTranslation();

  return (
    <UnauthNavigationLayout>
      <Head>
        <title>{t('nav.home')}</title>
      </Head>

      <Home />
    </UnauthNavigationLayout>
  );
};

const getStaticProps = async ({ locale }: { locale: string }) => ({
  props: {
    ...(await serverSideTranslations(locale)),
  },
});

export { getStaticProps };

export default HomePage;
